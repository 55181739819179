"use client";

import Image from "next/image";
import { BlocksRenderer, type BlocksContent } from "@strapi/blocks-react-renderer";
import { handleFixImageUrl } from "helpers";
import { TNextClientComponent } from "types/next";
type TProps = {
  content: BlocksContent;
};
const BlockRendererClient: TNextClientComponent<TProps> = props => {
  const {
    content
  } = props;
  if (!content) return null;
  return <BlocksRenderer content={content} blocks={{
    heading: props => {
      return <h4 {...props} className="font-bold text-xl mb-3" />;
    },
    paragraph: props => {
      return <p {...props} className="mb-3" />;
    },
    image: ({
      image
    }) => {
      return <Image src={handleFixImageUrl(image.url)} width={image.width} height={image.height} alt={image.alternativeText || "no-alt"} />;
    }
  }} data-sentry-element="BlocksRenderer" data-sentry-component="BlockRendererClient" data-sentry-source-file="BlockRendererClient.tsx" />;
};
export default BlockRendererClient;